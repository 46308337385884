import React, { useRef, useState } from 'react'
import { Accordion, Col, Container, Row } from 'react-bootstrap'
import './../scss/industry.scss'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import arrow from './../assets/arrow-right-black.png'

import case1 from './../assets/use-case/1.png'
import case2 from './../assets/use-case/2.png'
import case3 from './../assets/use-case/3.png'
import case4 from './../assets/use-case/4.png'
import case5 from './../assets/use-case/5.png'
import case6 from './../assets/use-case/6.png'
import case7 from './../assets/use-case/7.png'
import case8 from './../assets/use-case/8.png'

const settings = {
  dots: false,
  autoplay: false,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 0,
  swipe: false,
  arrows: false
}

const Industry = () => {
  const sliderRef = useRef()
  const [active, setActive] = useState(0)

  const handleClick = (idx) => {
    sliderRef.current.slickGoTo(idx)
    setActive(idx)
  }

  return(
    <Container className='pt-5 pb-5'>
      <div className='non-mobile'>
        <Row>
          <Col md={5}>
            {
              data.map((item, index) => {
                return(
                  <div key={index} className={`list-arrow ${active===index?'active':''}`}  onClick={()=>handleClick(index)}>
                    <div className='d-flex flex-row align-items-center'>
                      <div className='flex-grow-1 name'>{item.title}</div>
                      <img src={arrow} width="25px" alt="" />
                    </div>
                  </div>
                )
              })
            }
          </Col>
          <Col md={1}/>
          <Col md={6} className="industry-case">
            <div className='text-center-mobile'><strong className='text-brand'>Our Solution</strong></div>
            <div className="text-center-mobile header mt-2">Industry Use Cases</div>
            <div className='relative'>
              <div className='img-bg' />
            </div>

            <Slider ref={sliderRef} {...settings}>
              {
                data.map((item, index) => {
                  return(
                    <div key={index} style={{width:'100%'}}>
                      <p className='mt-2'>{item.desc}</p>
                      <img src={item.image} alt="" />
                    </div>
                  )
                })
              }
            </Slider>
          </Col>
        </Row>
      </div>
      <div className='mobile'>
        <div className='text-center-mobile'><strong className='text-brand'>Our Solution</strong></div>
        <div className="text-center-mobile header mt-2">Industry Use Cases</div>
        <Accordion defaultActiveKey="" className='mt-3'>
          {
            data.map((item, index) => {
              return(
                <Accordion.Item key={index} eventKey={index}>
                  <Accordion.Header>{item.title}</Accordion.Header>
                  <Accordion.Body>
                    {item.desc}
                    <img src={item.image} width="100%" className="mt-2" alt="" />
                  </Accordion.Body>
                </Accordion.Item>
              )
            })
          }
        </Accordion>
      </div>
    </Container>
  )
}

export default Industry

const data = [
  {
    title: 'Automotive',
    desc: 'Fluid experiences that are personalized and consistent across all channels, creating loyalty and earn revenue.',
    image: case1
  },
  {
    title: 'Education',
    desc: 'Simplifying complicated tasks and unifying data silos, to deliver tailored experience that better serve students and staff.',
    image: case2
  },
  {
    title: 'Financial',
    desc: 'Deliver standout customer experiences to get the edge in Financial Services.',
    image: case3
  },
  {
    title: 'Government',
    desc: 'To simplify processes and rapidly deliver personalized services earn the goodwill of their constituents.',
    image: case4
  },
  {
    title: 'Healthcare',
    desc: 'Drive the creation and delivery of relevant content that builds trust and loyalty.',
    image: case5
  },
  {
    title: 'Manufacturing',
    desc: 'Increase agility and respond to market shifts faster with customer insight and ﬂuid operations.',
    image: case6
  },
  {
    title: 'Retail',
    desc: 'Connect and personalize omnichannel retail experiences, to unify the shopping experience.',
    image: case7
  },
  {
    title: 'Travel & Hospitality',
    desc: 'Deliver human touches in the digital world that drive loyalty, delight travelers and give you a competitive edge.',
    image: case8
  },
  // {
  //   title: 'IT',
  //   desc: 'To providie memorable customer experiences. Earning greater engagement and customer loyalty with the power to structure and manage your customer experience on a global scale.',
  //   image: case2
  // },
]