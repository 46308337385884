import React, { useRef, useState } from "react"
import { Button, Col, Container, Row } from 'react-bootstrap'
import cn from "classnames";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import arrow from './../assets/arrow-next.png'
import accelerator from './../assets/accelerator.png'
import accelerator2 from './../assets/accelerator2.png'

import solution1 from './../assets/solution/1.png'
import solution2 from './../assets/solution/2.png'
import solution3 from './../assets/solution/3.png'
import solution4 from './../assets/solution/4.png'
import solution5 from './../assets/solution/5.png'
import solution6 from './../assets/solution/6.png'
import solution8 from './../assets/solution/8.png'
import solution9 from './../assets/solution/9.png'
import solution10 from './../assets/solution/10.png'
import useWindowSize from "../helpers/UseWindowSize";
import telco from './../assets/solution/telco.png'

import category1 from './../assets/category/1.png'
import category2 from './../assets/category/2.png'
import category3 from './../assets/category/3.png'
import category4 from './../assets/category/4.png'
import category5 from './../assets/category/telco.png'
import category6 from './../assets/category/cyber-icon.png'

const Accelerator = () => {
  const sliderRef = useRef()
  const elRef = useRef()
  const [flip, setFlip] = useState(false)
  const [width] = useWindowSize()

  const settings = {
    dots: false,
    autoplay: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipe: false,
    arrows: false
  }

  const handleClick = (e) => {
    if(flip){
      sliderRef.current.slickPrev()
    }else{
      sliderRef.current.slickNext()
    }
    
    if(width<768){
      elRef.current.scrollIntoView()
    }
    setFlip(!flip)
  }

  return(
    <div className='bg-pink'>
      <Container className='pt-5 pb-5'>
        <div className='text-center-mobile'><strong className='text-brand'>Our Solution</strong></div>
        <Row style={{alignItems:'center'}}>
          <Col xs={12} md={9}>
            <div className="text-center-mobile header mt-2">Growth to become Digital Accelerator</div>
          </Col>
          <Col xs={12} md={3}>
            <div className='relative pointer flip-card-outer' onClick={()=>handleClick()}>
              <div className={cn("flip-card-inner", { flip })}>
                <img className='arrow' src={arrow} alt=""/>
              </div>
            </div>
          </Col>
        </Row>

        <div ref={elRef}/>
        <Slider ref={sliderRef} {...settings}>
          {
            data.map((item, index) => {
              return(
                <div key={index} style={{width:'100%'}}>
                  <Row style={{alignItems:'center'}} className="mt-5">
                    <Col xs={12} md={12} lg={5}>
                      <img src={item.image} className="accelerator-img" alt=""  />
                      <p className='mt-3 mb-4 text-center-mobile'>In the contemporary business landscape, there is a discernible trend wherein both business customers and consumers exhibit a growing inclination towards self-service interactions with organizations. This preference is notably channeled through the seamless interfaces of web and mobile applications. These digital platforms not only personify convenience but also provide an avenue for accessing real-time data at the discretion of customers, thereby elevating the overall user experience.</p>
                    </Col>
                    <Col xs={12} md={12} lg={7}>
                      <div className='box-wrap'>
                        <Row>
                          {
                            item.data.map((dt, idx) => {
                              return(
                                <Col key={idx} xs={6} md={4} lg={6} className="mb-3">
                                  <div className='box bg-white' style={{borderRadius:5}}>
                                    <div className="text-center-mobile">
                                      <img src={dt.icon} className="box-icon" alt=""/>
                                    </div>
                                    <div className='title mt-3 text-center-mobile'>{dt.title}</div>
                                    <p className='mt-2 text-center-mobile'>{dt.desc}</p>
                                  </div>
                                </Col>
                              )
                            })
                          }
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </div>
              )
            })
          }
        </Slider>
        <div className="mobile text-center mt-3">
          <Button variant="brand" onClick={()=>handleClick()}>{!flip?'Next':'Previous'}</Button>
        </div>
      </Container>
    </div>
  )
}

export default Accelerator

const data = [
  {
    image: accelerator,
    data:[
      {
        icon: category6,
        title: 'Cyber Security',
        desc: 'It is an ongoing process that demands constant vigilance, investment, and collaboration to mitigate the risks associated with an ever-evolving digital landscape.'
      },
      {
        icon: category1,
        title: 'Advertising & Customer Engagement',
        desc: 'We make your brand relatable and meaningful through our customer engagement & advertising platform.'
      },
      {
        icon: category2,
        title: "Outsourcing & Talent Management",
        desc: "Providing professional manpower services for sales promotion and marketing purposes using technology."
      },
      {
        icon: category3,
        title: "e-Commerce",
        desc: "Building solution to enhance your e-commerce results from acquisition, performance data, to retention."
      },
      {
        icon: category4,
        title: "Tenant Management System",
        desc: "Helps real estate professionals using technology to manage and serve their tenants, including rental property owners, landlords, and property managers."
      },
      {
        icon: category5,
        title: "Telco Solutions",
        desc: "Providing high quality project management, systems integration, system design and network implementation services."
      }
    ]
  },
  {
    image: accelerator2,
    data: [
      {
        icon: solution6,
        title: 'RTMP Streaming ',
        desc: 'Provide realtime TV program solution along with schedule management, ads ingestion and reporting.'
      },
      {
        icon: solution8,
        title: 'Event Management',
        desc: 'Consumer Portal solution on web and mobile application, to enable user explore most favorite events, register as member and get easiness to register events, buy tickets into many events.'
      },
      {
        icon: solution9,
        title: 'IT Config Management',
        desc: 'Bring easiness to IT team to be more effective and efficient on managing IT service management, define and tweak workflow and centralize configuration.'
      },
      {
        icon: solution3,
        title: 'IT Development',
        desc: 'More than 8 years on having IT expertise teams and successfully deliver multiple projects on various business idustries from small to enterprise scale using Open Source Technology.'
      },
      {
        icon: solution10,
        title: 'Project Management',
        desc: 'Provide Project Management Service to lead, manage and accompany team to deliver projects on-time and qualified project outcomes.'
      },
      {
        icon: solution2,
        title: 'Outsourcing',
        desc: 'Experise on finding, managing the IT tallent to fullfill IT resource requirements both on project development and operations.'
      },
    ]
  }
]