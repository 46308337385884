import React, { useEffect } from 'react'
import { Container} from 'react-bootstrap'
import Accelerator from '../components/Accelerator'
import Industry from '../components/Industry'
import { AnimationOnScroll } from 'react-animation-on-scroll';
import "../scss/solution.scss"

import IMAGE1 from './../assets/solution/1.jpg'
import IMAGE2 from './../assets/solution/2.jpg'
import IMAGE3 from './../assets/solution/3.jpg'
import IMAGE4 from './../assets/solution/4.jpg'
import IMAGE5 from './../assets/solution/5.jpg'
import IMAGE6 from './../assets/solution/6.jpg'

const solution = [
  {
    image: IMAGE1,
    title: "Cyber Security",
    short_desc: "It is an ongoing process that demands constant vigilance, investment, and collaboration to mitigate the risks associated with an ever-evolving digital landscape."
  },
  {
    image: IMAGE2,
    title: "Advertising & Customer Engagement",
    short_desc: "We make your brand relatable and meaningful through our customer engagement & advertising platform."
  },
  {
    image: IMAGE3,
    title: "Outsourcing & Talent Management",
    short_desc: "Providing professional manpower services for sales promotion and marketing purposes using technology."
  },
  {
    image: IMAGE4,
    title: "e-Commerce",
    short_desc: "Building solution to enhance your e-commerce results from acquisition, performance data, to retention."
  },
  {
    image: IMAGE5,
    title: "Tenant Management System",
    short_desc: "Helps real estate professionals using technology to manage and serve their tenants, including rental property owners, landlords, and property managers."
  },
  {
    image: IMAGE6,
    title: "Telco Solutions",
    short_desc: "Providing high quality project management, systems integration, system design and network implementation services."
  }
]

const Solution = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0, 
      behavior: 'instant'
    });
  }, [])

  return(
    <div className='solution-wrap'>
      <Container className='pt-5 pb-5'>
        <AnimationOnScroll animateOnce={true} animateIn="animate__zoomIn" duration={.8} animatePreScroll={true}>
          <div className='header text-center-mobile'>EIS Technology Solution</div>
          <p className='mt-2 text-center-mobile'>Our technology solutions can span a wide range of applications and industries, leveraging various digital technologies to address unique challenges or opportunities.</p>
        </AnimationOnScroll>
        <AnimationOnScroll animateOnce={true} animateIn="animate__backInUp" duration={.8} animatePreScroll={true}>
          {/* <img src={imgsolution} className="pt-5 mt-5 pb-5 mb-5" width="100%" alt="" /> */}
          <div className="container-solution mt-5">
            {
              solution.map((item, index) => (
                <div key={'solution-'+index} className="card">
                  <img src={item.image}/>
                  <div className='card__content'>
                    <div className="card__head">{item.title}</div>
                    <div className="card__desc">{item.short_desc}</div>
                  </div>
                </div>
              ))
            }
          </div>
        </AnimationOnScroll>
      </Container>
      <Accelerator/>
      <AnimationOnScroll animateOnce={true} animateIn="animate__fadeIn" duration={.8}>
        <Industry/>
      </AnimationOnScroll>
    </div>
  )
}

export default Solution